import { render, staticRenderFns } from "./settings.vue?vue&type=template&id=8d89d076&scoped=true&"
import script from "./settings.vue?vue&type=script&lang=js&"
export * from "./settings.vue?vue&type=script&lang=js&"
import style0 from "./settings.vue?vue&type=style&index=0&id=8d89d076&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8d89d076",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseIntegratorFrame: require('/builds/wazzup/front/components/base/BaseIntegratorFrame.vue').default,AppPromoBanner: require('/builds/wazzup/front/components/app/AppPromoBanner.vue').default,AppGupshupBanner: require('/builds/wazzup/front/components/app/AppGupshupBanner.vue').default,SettingsHeader: require('/builds/wazzup/front/components/settings/SettingsHeader.vue').default,AppHeader: require('/builds/wazzup/front/components/app/AppHeader.vue').default,SettingsDrawer: require('/builds/wazzup/front/components/settings/SettingsDrawer.vue').default,AppDrawer: require('/builds/wazzup/front/components/app/AppDrawer.vue').default,AppRatingDialog: require('/builds/wazzup/front/components/app/AppRatingDialog.vue').default,AppOnboardingDialog: require('/builds/wazzup/front/components/app/AppOnboardingDialog.vue').default})


/* vuetify-loader */
import installComponents from "!../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VApp } from 'vuetify/lib/components/VApp';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VMain } from 'vuetify/lib/components/VMain';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VApp,VBtn,VCard,VCardActions,VCardText,VMain,VProgressLinear,VSpacer})


/* vuetify-loader */
import installDirectives from "!../node_modules/vuetify-loader/lib/runtime/installDirectives.js"
import ClickOutside from 'vuetify/lib/directives/click-outside'
installDirectives(component, {ClickOutside})
