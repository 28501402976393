//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapGetters, mapActions } from 'vuex';
import { deepCopy } from '~/utils/common';
import { accountTypes } from '~/enums/account';

const SCREEN_SIZE_ENABLE_COMPACT_SIDEBAR = 1439;

export default {
  name: 'LayoutSettings',

  middleware: 'auth',

  data() {
    return {
      drawer: false,
      promoClosed: false,
      showRateDialog: false,
      showOnboardingDialog: false,
      showOnboardingOverlay: false,
      showOnboardingOverlayDialog: false,
      holePosition: {
        top: 0,
        left: 0,
        width: '100%',
        height: '100%'
      }
    };
  },

  head() {
    return {
      htmlAttrs: {
        lang: this.account?.lang || 'ru',
        translate: 'no'
      },
      meta: [
        { name: 'language', content: this.langCode },
        { hid: 'viewport', name: 'viewport', content: 'width=1288, initial-scale=1' },
        { 'http-equiv': 'content-language', content: this.langCode }
      ]
    };
  },

  computed: {
    ...mapState('settingsLoader', ['settingsLoader']),
    ...mapState('auth', ['account', 'iFrameToken']),
    ...mapGetters('channels', ['nonGupshupActiveWabaChannels', 'showGupshup']),
    ...mapGetters('auth', ['integratorAccount', 'langCode', 'frontendData']),
    ...mapGetters('subscriptions', ['showAlertPromo751175']),

    holeStyle() {
      const { width, height } = this.holePosition;
      return { width, height };
    },

    showPromo() {
      return this.showAlertPromo751175 && !this.promoClosed;
    },

    getTitle() {
      return this.account.name
        ? `${this.$t('settings.subscriptions.clientAccount')}: ${this.account.name}`
        : this.$t('settings.subscriptions.clientAccount');
    },

    hasIntegrations() {
      return this.account?.integrations;
    },

    compactDrawer() {
      return this.$vuetify.breakpoint.width < SCREEN_SIZE_ENABLE_COMPACT_SIDEBAR;
    },

    isEmployee() {
      return this.account?.isEmployee;
    }
  },

  watch: {
    '$route.params': {
      deep: true,
      handler() {
        if (this.iFrameToken && !this.$route.query.token) {
          this.$router.push(`${this.$route.path}?token=${this.iFrameToken}`);
        }
      },
      immediate: true
    }
  },

  async mounted() {
    if (this.$config.m) location.href = this.$userPath('/chat');
    if (!this.$vuetify.breakpoint.mobile) this.drawer = true;
    this.$startUserflow();

    this.$nuxt.$on('start-help-onboarding', async () => {
      this.showOnboardingOverlay = true;
      await this.$nextTick();
      const menu = document.querySelector('.settings-drawer-footer-menu-help');
      const menuRect = menu.getBoundingClientRect();
      this.holePosition = {
        left: 8,
        top: menuRect.top - 3,
        width: menuRect.width,
        height: 87,
        cardLeft: 8 + menuRect.width + 8
      };
      setTimeout(() => {
        this.showOnboardingOverlayDialog = true;
      }, 400);
    });

    const frontendData = deepCopy(this.frontendData);

    // показ онбординга "Роли и CRM"
    try {
      const accountEmployee = this.account.type === accountTypes.EMPLOYEE;
      if (!accountEmployee && !this.integratorAccount && this.account.registerAt > 1724258292563) {
        if (frontendData.roleCrmOnboarding) {
          // 1724258292563 время релиза фичи
          if (!frontendData.roleCrmOnboarding.finishAt) {
            this.showOnboardingDialog = true;
          }
        } else {
          frontendData.roleCrmOnboarding = {
            startAt: Date.now(),
            finishAt: null,
            role: null,
            crm: null
          };
          await this.$services.user.setFrontendData(frontendData);
          await this.getCurrentAccount();
          this.showOnboardingDialog = true;
        }
      }
    } catch (error) {
      console.log('Start of onboarding "Role & CRM" failed!', error);
    }

    // показ модалки "Оценки" https://wazzup.planfix.ru/task/1021723
    if (!this.isEmployee && !this.integratorAccount) {
      try {
        const { firstSubscriptionPaymentDate } = this.account;
        // первая встреча с объектом ratingDialog
        if (!frontendData.ratingDialog) {
          // если после первой оплаты прошло 552 часа(23 дня)
          if (new Date(firstSubscriptionPaymentDate).getTime() + 552 * 60 * 60 * 1000 < Date.now()) {
            // показываем модалку и сохраняем frontendData.ratingDialog с начальными данными
            this.showRateDialog = true;
            frontendData.ratingDialog = {
              lastShownTimestamp: Date.now(), // дата, когда модалка была показана в последний раз
              isLastActionConfirm: false, // флаг, сигнализирующий, что кнопка "Отправить" не была нажата в последний показ модалки
              shownTimes: 1 // количество показов модалки
            };
            await this.$services.user.setFrontendData(frontendData);
            await this.getCurrentAccount();
          }
        } else {
          // модалка уже была показана в прошлом
          const { lastShownTimestamp, shownTimes, isLastActionConfirm } = frontendData.ratingDialog;
          // если это второй показ модалки, при не нажатой кнопке "Отправить" при первом показе
          if (shownTimes === 1 && lastShownTimestamp + 72 * 60 * 60 * 1000 < Date.now() && !isLastActionConfirm) {
            this.showRateDialog = true;
            frontendData.ratingDialog = {
              lastShownTimestamp: Date.now(),
              isLastActionConfirm: false,
              shownTimes: 2
            };
            await this.$services.user.setFrontendData(frontendData);
            await this.getCurrentAccount();
          } else if (lastShownTimestamp + 4392 * 60 * 60 * 1000 < Date.now()) {
            // все последующие показы через 4392 часа(183 дня) после последнего показа модалки
            this.showRateDialog = true;
            frontendData.ratingDialog = {
              lastShownTimestamp: Date.now(),
              isLastActionConfirm: false,
              shownTimes: shownTimes + 1
            };
            await this.$services.user.setFrontendData(frontendData);
            await this.getCurrentAccount();
          }
        }
      } catch (error) {
        console.log(error);
      }
    }
  },

  beforeDestroy() {
    this.$nuxt.$off('start-help-onboarding');
  },

  methods: {
    ...mapActions('auth', ['getCurrentAccount']),

    clickOutsideDrawer() {
      if (this.compactDrawer && !this.drawer) {
        this.drawer = true;
      }
    },

    async handlePromoClose() {
      const frontendData = deepCopy(this.frontendData);
      frontendData.promoPrices751175 = true;
      this.promoClosed = true;
      await this.$services.user.setFrontendData(frontendData);
    }
  }
};
