import jwt from 'jsonwebtoken';
import { clearPathFromAccountId, formatAccountId } from '~/utils/common';
import { errorCodes, errorStates } from '~/enums/errors';

export default async ({ app, store, $config, $sentry, route, $amp }) => {
  // инициализация amplitude
  $amp.init($config.AMPLITUDE_API_KEY);
  try {
    const userLang = (navigator.language || navigator.userLanguage)?.match(/^(ru|es|pt)-?/)?.[1];
    $amp.addProperty('Language', userLang);
    const RegistrationFormEnterAt = localStorage.RegistrationFormEnterAt;
    if (RegistrationFormEnterAt) $amp.addProperty('RegistrationFormEnterAt', RegistrationFormEnterAt);
  } catch (error) {
    console.log(error);
  }
  if (!window.IntersectionObserver) {
    await import('intersection-observer');
  }

  const regex = /\/(\d{4}-\d{4})/;
  const url = new URL(location.href);
  const IFrameToken = url.searchParams.get('token'); // если это iframe в crm, то в строке запроса будет параметр token с accessToken
  let accessToken = IFrameToken || url.searchParams.get('accessToken') || app.$cookies.get('accessToken'); // за токен берем сначала тот, что строке запроса. Если его нет, то берем из кукисов
  const refreshToken = url.searchParams.get('refreshToken');
  const authSection = ['chat', 'settings', 'backend', 'tech-partner']; // это возможные вхождения в pathname для закрытых разделов кабинета. backend обрабатывается отдельно, по своему.
  const decodedIFrameToken = jwt.decode(IFrameToken);
  const remoteAddChannel = !!decodedIFrameToken?.qrLink;
  $config.appDomain = url.hostname.match(/\.(.*)/)[1];
  let accountId =
    app.$cookies.get('accountId') ||
    app.$cookies.get('accountIdIframe') ||
    localStorage.getItem('accountIdIframe') ||
    decodedIFrameToken?.accountId ||
    0; // если обычный кабинет то accountId будет в куках, иначе достаем из токена интеграции

  const cabinetInIframe = !!url.searchParams.get('target');
  if (cabinetInIframe) {
    const target = url.searchParams.get('target');
    const domain = url.searchParams.get('domain');
    const isDomainActive = url.searchParams.get('isDomainActive');
    const state = url.searchParams.get('state');

    if (domain) {
      $config.cabinetInIframe = {
        target,
        domain,
        isDomainActive
      };
    } else if (state) {
      $config.cabinetInIframe = {
        target,
        state
      };
    }

    store.dispatch('auth/setCabinetInIframe', $config.cabinetInIframe);

    const accessTokenIframe = app.$cookies.get('accessTokenIframe') || localStorage.getItem('accessTokenIframe');
    const accountIdIframe = app.$cookies.get('accountIdIframe') || localStorage.getItem('accountIdIframe');
    if (accessTokenIframe) {
      accessToken = accessTokenIframe;
    }
    if (accountIdIframe) {
      accountId = accountIdIframe;
    }
  }

  const redirectToLogin = async () => {
    if ($config.m) {
      localStorage.removeItem('managerToken');
      localStorage.removeItem('managerAccountId');
    } else {
      app.$cookies.remove('accessToken', { path: '/', domain: `.${$config.appDomain}` });
      app.$cookies.remove('refreshToken', { path: '/', domain: `.${$config.appDomain}` });
      app.$cookies.remove('accountId', { path: '/', domain: `.${$config.appDomain}` });
    }
    await app.$redirect('/login/?redirect=' + clearPathFromAccountId(url.pathname));
  };

  if (!app.$cookies.get('__ref') && window.location.href.includes('utm_')) {
    app.$cookies.set('__ref', window.location.href, {
      domain: `.${$config.appDomain}`,
      path: '/',
      maxAge: 90 * 86400,
      sameSite: 'none',
      secure: true
    });
    if (!localStorage.getItem('__ref')) localStorage.setItem('__ref', window.location.href);
  }

  if (!app.$cookies.get('__referer')) {
    app.$cookies.set('__referer', document.referrer, {
      domain: `.${$config.appDomain}`,
      path: '/',
      maxAge: 90 * 86400,
      sameSite: 'none',
      secure: true
    });
    if (!localStorage.getItem('__referer')) localStorage.setItem('__referer', document.referrer);
  }

  // редирект с формата url с поддоменом(newXXXXXXXX) на новый формат
  const accountIdInSubdomain = /new(\d{8})/.exec(url.hostname)?.[1];
  if (accountIdInSubdomain) {
    const newHost = url.host.replace(/new\d{8}/, 'app');
    await app.$redirect(
      `${url.protocol}//${newHost}/${formatAccountId(accountIdInSubdomain)}${url.pathname}${url.search}`
    );
    return;
  }

  if (IFrameToken) store.dispatch('auth/setIFrameToken', IFrameToken); // сохраняем токен из параметров строки запроса. Используем его в axios и в ChatDrawer.vue
  // фиксируем в конфиг, что мы находимся на поддомене web.wazzup24.com
  if (url.hostname.includes('web.')) {
    $config.m = true;
    accessToken = localStorage.getItem('managerToken');
    accountId = localStorage.getItem('managerAccountId');
    if (accountId) store.dispatch('auth/setXAccountId', accountId);
    if (accessToken) store.dispatch('auth/setIFrameToken', accessToken);
    try {
      if (Notification.permission === 'default') Notification.requestPermission();
    } catch (err) {
      console.log(err);
    }
  }

  // проверка accountId в url на корректность
  if (route.params?.accountId && accountId) {
    if (/^\d{8}$/.test(route.params.accountId)) {
      await app.$redirect(location.href.replace(route.params.accountId, formatAccountId(route.params.accountId)));
    } else if (!/^\d{4}-\d{4}$/.test(route.params.accountId)) {
      await app.$redirect(location.href.replace(route.params.accountId, formatAccountId(accountId)));
    }
  }

  // определяем в какой раздел пытается попасть пользователь
  if (authSection.find((item) => url.pathname.includes(item)) || regex.test(url.pathname)) {
    // это закрытый раздел
    // если это вэб версия, но нет токена, редирект на логин

    if ($config.m && !jwt.decode(accessToken)?.integration) {
      await app.$redirect('/login/?redirect=' + clearPathFromAccountId(url.pathname));
    }

    if (regex.test(url.pathname) && !$config.m) {
      if (!IFrameToken && !cabinetInIframe) {
        app.$cookies.set('accessToken', accessToken, {
          path: '/',
          domain: `.${$config.appDomain}`,
          maxAge: 2592000,
          sameSite: 'none',
          secure: true
        });
      }
      accountId = regex.exec(url.pathname)[1].replace('-', '');
      store.dispatch('auth/setXAccountId', accountId);
    }

    // У нас есть accessToken и accountId. Это все что нужно для работы всех роутов
    if (accessToken && accountId) {
      //  Тип токена: страница
      const pages = {
        wazzupTemplate: 'templates-wazzup',
        wabaTemplate: 'templates-waba',
        channel: 'channel',
        wabaProfile: 'waba-profile'
      };

      //  Пользователь находится на одной из страниц шаблонов для тех. партнера
      const currentUrlHasTechPartnerPage = Object.values(pages)
        .map((page) => `/tech-partner/${page}`)
        .some((path) => url.pathname.includes(path));
      //  Страница шаблонов совпадает с типом токена тех. партнера
      const pageMatchesTokenType = url.pathname.includes(pages[decodedIFrameToken?.techPartner?.tokenType]);

      if (decodedIFrameToken?.techPartner && !(currentUrlHasTechPartnerPage && pageMatchesTokenType)) {
        await app.$redirect(
          `/${formatAccountId(accountId)}/tech-partner/${pages[decodedIFrameToken?.techPartner?.tokenType]}${
            url.search
          }`
        );
      }

      if (!regex.test(url.pathname)) {
        await app.$redirect(`/${formatAccountId(accountId)}${url.pathname}${url.search}`);
      }

      // запишем accountId в хранилище для его доступности в axios
      store.dispatch('auth/setXAccountId', accountId);
      if (store.state?.auth?.iFrameToken) {
        try {
          await Promise.all([
            store.dispatch('auth/getCurrentAccount'),
            store.dispatch('channels/getChannels'),
            store.dispatch('subscriptions/getSubscriptionsConstants')
          ]);
          if (!decodedIFrameToken?.techPartner) {
            await store.dispatch('auth/getIntegrationInfo');
          }
        } catch (err) {
          const errorCode = err.response?.data?.errors?.[0]?.code;
          console.log(err);

          if ($config.m) {
            await redirectToLogin();
            return;
          }

          // что то пошло не так. покажем заглушку "что-то пошло не так" в чатах
          if (
            ![
              errorCodes.noRole,
              errorCodes.noIntegration,
              errorCodes.noIntegrationData,
              errorCodes.invalidToken
            ].includes(errorCode)
          ) {
            store.dispatch('auth/setChatErrorState', errorStates.initError);
          }
        }
      } else {
        try {
          await Promise.all([
            store.dispatch('auth/getCurrentAccount'),
            store.dispatch('channels/getChannels'),
            store.dispatch('auth/getCurrentUser'),
            store.dispatch('subscriptions/getSubscriptionsConstants'),
            store.dispatch('auth/getUsers')
          ]);
        } catch (err) {
          console.log(err);
          // что то пошло не так. перебросим на логин
          await redirectToLogin();
          return;
        }
      }

      // установка id юзера amplitude
      try {
        $amp.setUserId(String(accountId));
        $amp.addProperty('Account_type', store.state?.auth?.account?.type);
        $amp.addProperty('HasPartner', Boolean(store.state?.auth?.account?.partnerRef));
        $amp.addProperty('Currency', store.state?.auth?.account?.currency);
        $amp.addProperty('CountryName', store.state?.auth?.user?.details?.country);
        $amp.addProperty('referer', store.state?.auth?.user?.details?.registrationInfo?.referer);
        const utm = store.state?.auth?.user?.details?.registrationInfo?.url.match(/utm_p=(.*)$/)[1];
        if (utm) $amp.addProperty('utm_source', utm);
      } catch (error) {
        console.log(error);
      }

      // если аккаунт не активирован, перенаправляем на регистрацию
      if (!store.state?.auth?.iFrameToken && !store.state?.auth?.user?.activated) {
        if (cabinetInIframe) {
          app.$cookies.set('accessTokenIframe', accessToken, {
            path: '/',
            domain: `.${$config.appDomain}`,
            maxAge: 2592000,
            sameSite: 'none',
            secure: true
          });
          localStorage.setItem('accessTokenIframe', accessToken);
          app.$cookies.set('accountIdIframe', accountId, {
            path: '/',
            domain: `.${$config.appDomain}`,
            maxAge: 2592000,
            sameSite: 'none',
            secure: true
          });
          localStorage.setItem('accountIdIframe', accountId);
        } else {
          app.$cookies.set('accessToken', accessToken, {
            path: '/',
            domain: `.${$config.appDomain}`,
            maxAge: 2592000,
            sameSite: 'none',
            secure: true
          });
          app.$cookies.set('accountId', accountId, {
            path: '/',
            domain: `.${$config.appDomain}`,
            maxAge: 2592000,
            sameSite: 'none',
            secure: true
          });
        }

        if (!$config.m) await app.$redirect('/signup');
      }

      // записывам accessToken в стор
      store.dispatch('auth/setAccessToken', IFrameToken || url.searchParams.get('accessToken') || accessToken);

      // присваиваем язык интерфейса
      await app.i18n.setLocale(store.state.auth.account.lang);

      const { id, regEmail } = app.store.state?.auth?.account;
      $sentry.setUser({
        id: id || accountId,
        email: regEmail
      });
      // уходит в сентри под именем integrationUserId)
      $sentry.setExtra(decodedIFrameToken?.integration?.userId);
    } else {
      // если нет токена, то перенаправляем на страницу логина
      // и приписываем параметр redirect, что по нему вернуть
      // пользователя на страницу, на которую он пытался попасть
      await redirectToLogin();
    }
  } else if (accessToken && !url.pathname.includes('backend') && !$config.m && !remoteAddChannel) {
    if (regex.test(url.pathname) && !IFrameToken) {
      accountId = regex.exec(url.pathname)[1];
      if (cabinetInIframe) {
        app.$cookies.set('accessTokenIframe', accessToken, {
          path: '/',
          domain: `.${$config.appDomain}`,
          maxAge: 2592000,
          sameSite: 'none',
          secure: true
        });
        localStorage.setItem('accessTokenIframe', accessToken);
        if (refreshToken) {
          app.$cookies.set('refreshTokenIframe', refreshToken, {
            domain: `.${$config.appDomain}`,
            path: '/',
            maxAge: 2592000,
            sameSite: 'none',
            secure: true
          });
          localStorage.setItem('refreshTokenIframe', refreshToken);
        }
        app.$cookies.set('accountIdIframe', accountId, {
          path: '/',
          domain: `.${$config.appDomain}`,
          maxAge: 2592000,
          sameSite: 'none',
          secure: true
        });
        localStorage.setItem('accountIdIframe', accountId);
      } else {
        app.$cookies.set('accessToken', accessToken, {
          path: '/',
          domain: `.${$config.appDomain}`,
          maxAge: 2592000,
          sameSite: 'none',
          secure: true
        });
        if (refreshToken) {
          app.$cookies.set('refreshToken', refreshToken, {
            domain: `.${$config.appDomain}`,
            path: '/',
            maxAge: 2592000,
            sameSite: 'none',
            secure: true
          });
        }
        app.$cookies.set('accountId', accountId, {
          path: '/',
          domain: `.${$config.appDomain}`,
          maxAge: 2592000,
          sameSite: 'none',
          secure: true
        });
      }
      store.dispatch('auth/setXAccountId', accountId);
    }
    try {
      // это нужно что бы определить, что пользователь активирован
      store.dispatch('auth/setXAccountId', accountId || app.$cookies.get('accountId'));
      if (accountId) {
        await store.dispatch('auth/getCurrentUser');
      }
    } catch (err) {
      console.log(err);
      // что то пошло не так. перебросим на логин
      location.href = '/';
    }
  } else if (remoteAddChannel) {
    store.dispatch('auth/setAccessToken', url.searchParams.get('token'));
    store.dispatch('auth/setXAccountId', decodedIFrameToken.accountId);
  }
};
